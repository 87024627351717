const useRecaptcha = () => {

  // const { executeRecaptcha } = useGoogleReCaptcha();

  return {
    verify: async (): Promise<string> => {
      const siteKey = '6LdfzOspAAAAAEYN0urhIuun05W45FQwEh3-9rhq';//import.meta.env.VITE_RECAPTCHA_SITE_KEY
      return await window.grecaptcha.enterprise.execute(siteKey, {action: 'submitContact'})
    }
  }
}


export default useRecaptcha
