import {useForm} from "@inertiajs/react";
import axios, {AxiosError} from "axios";
import {useState} from "react";



interface PostOptions {
  extraData?: object,
}
export default function useAjaxForm<TForm extends object>(initialValues?: TForm) {

  const { data, setData, errors, setError, clearErrors, reset} = useForm(initialValues)
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);

  const post = async (url: string, options: PostOptions) => {


    try {
      clearErrors()
      setProcessing(true)

      const finalData = (options.extraData) ? {...data, ...options.extraData} : data;
      const response = await axios.post(url, finalData)

      setSuccess(true)
      reset();
    } catch (err) {

      const errors = err as Error | AxiosError;
      if (axios.isAxiosError(errors)) {
        const {data, status} = errors.response || {}
        setError(data.errors)
      }
    } finally {
      setProcessing(false)
    }

  }

  return {
    data, setData, errors, setError, clearErrors, reset, post, processing, success, setSuccess
  }
}



